
html, body, #root {
  height: 100vh; // Ensure the root container takes full height
  margin: 0; // Remove default margin
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9fafb;
}

h1, h2 {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  transition: color 0.3s;
}

.roboto-thin {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: italic;
}

