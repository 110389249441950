// Variables
$footer-bg: #333;
$text-color: #fff;
$link-hover-color: #31708f;

// Footer Container
.footer {
  background-color: $footer-bg;
  color: $text-color;
  text-align: center;
  padding: 20px;
  margin-bottom: 0;
  height: 50px;
//   width: 100%;

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;

    p {
      font-size: 1rem;
    }

    .footer-nav ul {
      display: flex;
      justify-content: center;
      gap: 20px;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        a {
          text-decoration: none;
          color: $text-color;
          transition: color 0.3s ease;

          &:hover {
            color: $link-hover-color;
          }
        }
      }
    }
  }
}
